export enum ModuleType {
	Village,
	Object,
	Content
}

export enum ModuleTypeEnum {
	Village = 'village',
	Object = 'object',
	Content = 'content',
}